import React from "react";
import zee from "../../assets/logo/1024-white-zee.png";
// import axios from "axios";
// import * as yup from "yup";
// import { API_URL } from "../../core/constants";
// import Toast from "../Toast/Toast";

const Footer = (props) => {
  // const [mailchimpEmailToSubscribe, setMailchimpEmailToSubscribe] =
  //   useState("");
  // const [disabled, setDisabled] = useState(false);

  // const onMailchimpEmailChangeHandler = (event) => {
  //   setMailchimpEmailToSubscribe(event.target.value);
  // };

  // const doMailchimpSubscribeHandler = async (e) => {
  //   const payload = {
  //     email: mailchimpEmailToSubscribe,
  //   };

  //   const schema = yup.object().shape({
  //     email: yup.string().email().required(),
  //   });

  //   const validated = await schema.isValid(payload);
  //   if (!validated) {
  //     Toast.popError(
  //       `It looks like there is a problem with that email address, please check and try again.`
  //     );
  //     console.log("Not validated", validated);
  //     return false;
  //   } else {
  //     axios
  //       .post(`${API_URL}/mailchimp`, payload)
  //       .then(function (response) {
  //         console.log("Success", response);
  //         Toast.popSuccess(
  //           `Successfully subscribed to our newsletter, thank you!`
  //         );
  //         setMailchimpEmailToSubscribe("");
  //         setDisabled(true);
  //       })
  //       .catch(function (error) {
  //         Toast.popError(
  //           "Oh no, something has gone wrong. Please try again or contact us.",
  //           { autoClose: false }
  //         );
  //         console.log(error);
  //       });
  //   }
  // };

  const renderUKText = () => {
    return (
      <>
        <p className="text-sm text-gray-700 sm:ml-6">
          &copy; 2025 ZeroPA Ltd. All rights reserved.
          <br />
          <span className="text-gray-500 text-xs">
            <span className="underline">Loans are provided by:</span>
            <br />
            ZeroPA Ltd (Company No. 06872579) in England and Wales, registered
            office address: Wenta Business Centre, Colne Way, Watford, England
            WD24 7ND.
            <br />
            <br />
            <span className="underline">Grants are provided by:</span>
            <br />
            ZeroPA CIC (Company No. 13285651), registered office address: 27-29
            High Street, Biggleswade, England SG18 0JE.
            <br />
            <br />
            <span className="underline">Region:</span> United Kingdom
          </span>
        </p>
      </>
    );
  };

  return (
    <footer className="text-gray-600 body-font">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-wrap md:text-left text-center order-first text-base">
          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
              FINANCE
            </h2>
            <nav className="list-none mb-10">
              <li>
                <a
                  href="/loans"
                  className="text-gray-600 hover:text-gray-800 cursor-pointer"
                >
                  Loans
                </a>
              </li>
              <li>
                <a
                  href="/grants"
                  className="text-gray-600 hover:text-gray-800 cursor-pointer"
                >
                  Grants
                </a>
              </li>
              <li>
                <a
                  href="/apply"
                  className="text-gray-600 hover:text-gray-800 cursor-pointer"
                >
                  Apply for a loan
                </a>
              </li>
              <li>
                <a
                  href="/apply"
                  className="text-gray-600 hover:text-gray-800 cursor-pointer"
                >
                  Apply for a grant
                </a>
              </li>
              <li>
                <a
                  href="/benefits"
                  className="text-gray-600 hover:text-gray-800 cursor-pointer"
                >
                  Benefits Calculator
                </a>
              </li>
            </nav>
          </div>
          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
              HELP &amp; SUPPORT
            </h2>
            <nav className="list-none mb-10">
              <li>
                <a
                  href="/contact"
                  className="text-gray-600 hover:text-gray-800"
                >
                  Help
                </a>
              </li>
              <li>
                <a
                  href="https://kestrl.io"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="text-gray-600 hover:text-gray-800 cursor-pointer"
                >
                  Kestrl Money Management
                </a>
              </li>
              <li>
                <a
                  href="https://novamoney.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="text-gray-600 hover:text-gray-800 cursor-pointer"
                >
                  Nova Money
                </a>
              </li>
            </nav>
          </div>
          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
              ABOUT
            </h2>
            <nav className="list-none mb-10">
              <li>
                <a
                  href="/about"
                  className="text-gray-600 hover:text-gray-800 cursor-pointer"
                >
                  About
                </a>
              </li>
              <li>
                <a
                  href="/how-it-works"
                  className="text-gray-600 hover:text-gray-800 cursor-pointer"
                >
                  How it works
                </a>
              </li>
              <li>
                <a
                  href="/investors"
                  className="text-gray-600 hover:text-gray-800 cursor-pointer"
                >
                  Investors &amp; Lending
                </a>
              </li>
              <li>
                <a
                  href="/contact"
                  className="text-gray-600 hover:text-gray-800 cursor-pointer"
                >
                  Contact
                </a>
              </li>
            </nav>
          </div>
          <div className="lg:w-1/4 md:w-1/2 w-full px-4 text-base">
            {/* https://dev.to/gedalyakrycer/create-an-email-list-with-react-mailchimp-965 */}
            {/* <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
              SUBSCRIBE
            </h2>
            <div className="flex xl:flex-nowrap md:flex-nowrap lg:flex-wrap flex-wrap justify-center items-end md:justify-start">
              <div className="relative w-40 sm:w-auto xl:mr-4 lg:mr-0 sm:mr-4 mr-2">
                <label
                  htmlFor="footer-field"
                  className="leading-7 text-gray-600"
                >
                  Your email
                </label>
                <input
                  value={mailchimpEmailToSubscribe}
                  onChange={onMailchimpEmailChangeHandler}
                  type="text"
                  id="footer-field"
                  name="footer-field"
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:bg-transparent focus:ring-2 focus:ring-purple-200 focus:border-purple-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
              <button
                onClick={doMailchimpSubscribeHandler}
                disabled={disabled ? "disabled" : null}
                className="lg:mt-2 xl:mt-0 flex-shrink-0 inline-flex text-white bg-purple-500 border-0 py-2 px-6 focus:outline-none hover:bg-purple-600 rounded"
              >
                Subscribe
              </button>
            </div>
            <p className="text-gray-500 text-sm mt-2 md:text-left text-center">
              Subscribe for details of new products, offers &amp; partners. We
              will never spam or sell your details.
            </p> */}
          </div>
        </div>
      </div>
      <div className="bg-gray-100">
        <div className="container px-5 py-6 mx-auto flex items-center sm:flex-row flex-col">
          <a
            href="/"
            className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900"
          >
            <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-purple-800 text-purple-800 flex-shrink-0">
              <img src={zee} className="w-8 h-8" alt="ZeroPA" />
            </div>
          </a>
          {renderUKText()}
          <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
            <a
              href="https://facebook.com/zeropamadadcic"
              rel="noopener noreferrer"
              target="_blank"
              className="text-gray-500"
            >
              <svg
                fill="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
              </svg>
            </a>
            {/* <a
              href="https://twitter.com/zeropa"
              rel="noopener noreferrer"
              target="_blank"
              className="ml-3 text-gray-500"
            >
              <svg
                fill="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
              </svg>
            </a> */}
            <a
              href="https://instagram.com/zeropa_madad"
              rel="noopener noreferrer"
              target="_blank"
              className="ml-3 text-gray-500"
            >
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
              </svg>
            </a>
            <a
              href="https://linkedin.com/company/zeropa/"
              rel="noopener noreferrer"
              target="_blank"
              className="ml-3 text-gray-500"
            >
              <svg
                fill="currentColor"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="0"
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="none"
                  d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
                ></path>
                <circle cx="4" cy="4" r="2" stroke="none"></circle>
              </svg>
            </a>
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
